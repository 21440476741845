// Global packages and components
import { clsx } from 'clsx'

// Types
export interface PillProps {
  label: string
  style?: 'green' | 'red'
  size?: 'xs' | 'sm'
}

// Main export
const Pill = ({ label, style = 'green', size = 'sm' }: PillProps) => (
  <div
    className={clsx('rounded-full px-2.5 py-1.5 font-semibold text-white', {
      'bg-teal-green': style == 'green',
      'bg-red-700': style == 'red',
      'text-xs': size == 'sm',
      'text-2xs': size == 'xs',
    })}
  >
    {label}
  </div>
)

export default Pill
