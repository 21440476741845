// Global packages and components
import { clsx } from 'clsx'

// Types
export interface RoundalProps {
  label: string
  style?: 'green' | 'red'
  size?: 'xs' | 'sm' | 'md'
}

// Main export
const Roundal = ({ label, style = 'green', size = 'sm' }: RoundalProps) => (
  <div
    className={clsx(
      'flex size-14 items-center justify-center rounded-full p-2 font-program font-semibold text-white',
      {
        'bg-teal-green': style == 'green',
        'bg-red-700': style == 'red',
        'text-base': size == 'md',
        'text-sm': size == 'sm',
        'text-xs': size == 'xs',
      }
    )}
  >
    <div className="table-caption text-center uppercase leading-none">
      {label}
    </div>
  </div>
)

export default Roundal
