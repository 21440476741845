// Global packages and components
import { clsx } from 'clsx'
import { Roundal, Pill } from '~/components'

// Types
import type { RoundalProps } from '~/components/partials/common/roundal'

interface Props {
  src: string
  alt?: string
  tags?: RoundalProps[]
  tagsType?: 'roundal' | 'pill'
  adaptive?: boolean
  hasVariations?: boolean
}

// Main export
const ProductImage = ({
  src,
  alt,
  tags,
  tagsType = 'roundal',
  adaptive = false,
  hasVariations = false,
}: Props) => {
  return (
    <div
      className={clsx(
        'relative flex items-center justify-center overflow-hidden rounded-lg bg-white',
        {
          'h-full w-full': adaptive,
          'aspect-square': !adaptive,
        }
      )}
    >
      {tags && tags.length !== 0 && (
        <ul className="absolute right-0 top-0 z-[1] -mx-0.5 flex p-1.5">
          {tags.map((tag, i) => (
            <li
              className="px-0.5"
              key={i}
            >
              {tagsType == 'pill' ? (
                <Pill
                  {...tag}
                  size="sm"
                />
              ) : (
                <Roundal
                  {...tag}
                  size="md"
                />
              )}
            </li>
          ))}
        </ul>
      )}
      {hasVariations && (
        <div className="absolute bottom-0 left-0 p-1.5 md:p-2.5">
          <div className="p2-x z-[1] inline-block rounded-full border border-teal-green bg-white px-2 py-0.5 md:px-3 md:py-1">
            <p className="mb-0 text-2xs font-semibold md:text-xs">
              Variations
              <span className="hidden md:inline">&nbsp;available</span>
            </p>
          </div>
        </div>
      )}
      <img
        src={src}
        alt={alt ?? ''}
        className="max-h-full w-auto max-w-full object-contain"
      />
    </div>
  )
}

export default ProductImage
